import "../style.scss";

export var Hls = require('../../node_modules/hls.js/dist/hls.light.js');

if (Hls.isSupported()) {
    console.log("HLS supported");
    var videos = document.getElementsByClassName('video');
    for(var video of videos) {
      var hls = new Hls();
      const manifestUri = video.getAttribute("src");
      hls.loadSource(manifestUri);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
      });
    }
}
